import { autorun, observable, toJS } from "mobx";
import settings from "../data/Settings.json";
import axios from "axios";

class ListingStore {
  @observable token = null;
  @observable listings = null;
  @observable currentListing = null;
  @observable displays = null;
  @observable assets = null;
  @observable filteredAssets = null;
  @observable selectList = [];
  @observable activeListingList = null;

  constructor() {
    this.apiUrl = settings.apiUrl;
    this.tokenUrl = settings.tokenUrl;
    this.clientId = settings.clientId;
    this.clientSecret = settings.clientSecret;

    this.assetOptions = {
      showSchedule: true,
      initialTab: 'displays',
      successMessage: 'Saved config',
      loadingMessage: 'Saving...',
      showDeployDialog: true,
      showDuration: true
    };

    this.noDialogOptions = {
      noDialog: true,
      showDeployDialog: false,
      showSchedule: false
    };
  }

  getToken = () => {
    let params = new URLSearchParams();
    params.append("client_id", this.clientId);
    params.append("client_secret", this.clientSecret);
    params.append("grant_type", "client_credentials");
    params.append("scope", "api_agencies_read api_listings_read");
    
    return axios.post(this.tokenUrl, params)
    .then(response => {
      this.token = response.data.access_token;
      return response;
    });
  }

  setListings = (agencyId) => {
    let requests = [];
    const url = `${this.apiUrl}/agencies/${agencyId}/listings`;
    
    for (let index = 1; index <= 2; index++) {
      const data = {
        params: { pageNumber: index, pageSize: 200 },
        headers: {"Authorization" : `Bearer ${this.token}`}
      };
  
      requests.push(axios.get(url, data)); 
    }
    
    return axios.all(requests)
    .then(responses => {
      this.listings = [];
      responses.forEach(response => {
        this.listings = this.listings.concat(response.data);
      });
    })
    .then(r => this.prepareActiveListings());
  }

  setCurrentListing = (item) => {
    if (item) {
      this.currentListing = item;
    }
  }

  resetListings = () => {
    this.listings = null;
    this.currentListing = null;
    this.activeListingList = null;
  }

  getListingById = (id) => {
    let result = null;
    this.listings.forEach(item => {
      if (item.id == id) {
        result = item;
      }
    }); 

    return result;
  }

  prepareActiveListings = () => {
    const activeListings = this.getActiveListings();
    let listings = [];

    // Add additional fields. Set active status and display names for listing.
    this.listings.forEach(listing => {
      let obj = {
        isActive: false,
        displayNames: [],
        displayLength: 0
      };

      activeListings.forEach((activeListing, index) => {
        if (listing.id == activeListing.itemId) {
          obj.isActive = true;
          let displayIds = activeListing.VenueIds;
          obj.displayNames = displayIds.map(this.getDisplayNameByID);
          obj.displayLength = displayIds.length;
        }
      });

      obj = Object.assign(obj, listing);
      listings.push(obj);
    });

    this.listings = listings;
  }

  filterListings = (field, order = "ASC") => {
    const listings = toJS(this.listings);
    let result = [];

    if (field == "address") {
      result = listings.sort((a, b) => {
        const item1 = a.addressParts.displayAddress;
        const item2 = b.addressParts.displayAddress;

        if (item1 > item2) return -1;
        else if (item1 < item2) return 1;
        else return 0;
      });
    }

    if (field == "display") {
      result = listings.sort((a, b) => {
        const item1 = a.displayLength;
        const item2 = b.displayLength;
        return item2 - item1;
      });
    }

    this.listings = order == "ASC" ? result : result.reverse();
  }

  // Assets
  getAssets = () => {
    window.enplug.account.getAssets(response => {
      this.assets = response;
      this.filteredAssets = response;
      this.activeListingList = response.map(item => {
        let result = Object.assign({}, item.Value);
        result.VenueIds = item.VenueIds;
        return result;
      });
    }, this.onError);
  }

  getActiveListings = () => {
    return this.activeListingList;
  }

  addAsset = (value, options = null, VenueIds = null, callback = null) => {
    window.enplug.account.getAssets(r => {
      this.saveAsset(value, options, VenueIds, callback);
    }, this.onError);
  }

  saveAsset = (value, options = null, VenueIds = null, callback = null) => {
    const assetOptions = options ? options : this.assetOptions;
    let asset = {
      Id: null,
      Value: value,
      VenueIds: []
    };

    if (VenueIds) {
      asset.VenueIds = VenueIds;
    }
          
    window.enplug.account.saveAsset(asset, assetOptions, r => {
      this.getAssets();
      if (callback) {
        callback(r);
      }
    },
    this.onError);
  }

  editAsset = (asset, options = null, callback = null) => {
    const assetOptions = options ? options : this.assetOptions;

    window.enplug.account.saveAsset(asset, assetOptions, r => {
      this.getAssets();
      if (callback) {
        callback(r);
      }
    },
    this.onError);
  }

  filterAssetsByDisplay = (displayId) => {
    this.filteredAssets = this.assets.filter(asset => {
      if (displayId == "") {
        return true;
      }

      return asset.VenueIds && asset.VenueIds.indexOf(displayId) > -1;
    });
  }

  reorderAssets = (assets) => {
    let items = toJS(this.filteredAssets);

    assets.forEach((asset, index) => {
      items[index].Value = asset.Value;
      items[index].VenueIds = asset.VenueIds;
      this.editAsset(items[index], this.noDialogOptions);
    });
  }
    
  deleteAsset = (id) => {
    return new Promise((resolve, reject) => {
      window.enplug.account.deleteAsset(id + "", r => {
        window.enplug.dashboard.successIndicator("Delete success");
        this.getAssets();
        resolve("");
      }, 
      error => {
        console.log(error);
        reject("Something goings wrong");
        window.enplug.dashboard.errorIndicator("Something goings wrong");
      });
    });    
  }

  deleteAllAssets = (e) => {
    let list = [];
    this.assets.forEach(asset => {
      list.push(this.deleteAsset(asset.Id));
    });

    return Promise.all(list);
  }

  addAllAssets = (status, options) => {
    let assets = [];
    assets.push({
      Id: null,
      Value: Object.assign({ status: status }, options),
      VenueIds: []
    });

    window.enplug.dashboard.pageLoading(true);
    window.enplug.account.bulkCreateAssets(assets, this.assetOptions, r => {
      window.enplug.dashboard.pageLoading(false);
      this.getAssets();
    }, this.onError);
  }

  // Agent
  getAgent = (id) => {
    const url = `https://api.domain.com.au/v1/agents/${id}`;
    const data = { 
      headers: {"Authorization" : `Bearer ${this.token}`}
    };

    return axios.get(url, data);
  }

  // Displays
  getDisplays = () => {
    window.enplug.account.getDisplayGroups(response => {
      this.displays = response;
    });
  }

  getDisplayList = (venueIds) => {
    return this.displays.map(display => {
      if (venueIds.indexOf(display.Id) > -1) {
        return display;
      }
    });
  }

  getDisplayNameByID = (id) => {
    let result = "";
    this.displays.forEach(display => {
        if (display.Id == id) {
            result = display.Name;
        }
    });

    return result;
  }

  // Multiselect
  resetSelectList = () => {
    this.selectList = [];
  }

  addToSelectList = (listingId) => {
    this.selectList.push(listingId);
  }

  removeFromSelectList = (listingId) => {
    const index = this.selectList.indexOf(listingId);
    this.selectList.splice(index, 1);
  }

  addMultipleAssets = (assets, options) => {
    window.enplug.dashboard.pageLoading(true);
    window.enplug.account.bulkCreateAssets(assets, options, r => {
      this.selectList = [];
      this.getAssets();
      window.enplug.dashboard.pageLoading(false);
    }, this.onError);
  }
  
  addSelectListToPlayer = (userSettings) => {
    let assets = [];
    this.selectList.forEach(id => {
      const asset = {
        Id: null,
        Value: Object.assign({ itemId: id }, userSettings),
        VenueIds: []
      };
      assets.push(asset);
    });

    this.addMultipleAssets(assets, this.assetOptions);
  }

  editSelectList = () => {
    let assets = [];
    this.assets.forEach(asset => {
      if (this.selectList.indexOf(asset.Id) > -1) {
        assets.push(asset);
      }
    });

    this.editAsset(assets[0], null, response => {
      assets.forEach((asset, index) => {
        if (index != 0) {
          asset.VenueIds = response.VenueIds;
          this.editAsset(asset, this.noDialogOptions);
        }
      });

      this.selectList = [];    
    });
  }

  clearSelectList = () => {
    this.selectList = [];
  }

  // Other
  onError = (error) => {
    console.log(error);
    window.enplug.dashboard.pageLoading(false);
    window.enplug.dashboard.errorIndicator("Something goings wrong");
  }
}

const store = window.ListingStore = new ListingStore;

export default store;

autorun(() => {
    console.log(store);
});